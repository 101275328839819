var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        {
          staticClass: "ma-4",
          attrs: { cols: "12", sm: "10", md: "8", lg: "6" },
        },
        [
          _vm.showAlert
            ? _c(
                "v-alert",
                { attrs: { type: "error" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { staticClass: "grow" }, [
                        _vm._v(
                          " Falha na autenticação. Verifique suas credenciais. "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card",
            { ref: "form" },
            [
              _c(
                "v-card-text",
                [
                  _vm._v(" LOGIN: "),
                  _c("v-text-field", {
                    ref: "nameTOTVS",
                    attrs: {
                      rules: [() => !!_vm.nameTOTVS || "INFORME O USUARIO"],
                      label: "Nome de usuario",
                      placeholder: "USUARIO",
                      required: "",
                    },
                    model: {
                      value: _vm.nameTOTVS,
                      callback: function ($$v) {
                        _vm.nameTOTVS = $$v
                      },
                      expression: "nameTOTVS",
                    },
                  }),
                  _c("v-text-field", {
                    ref: "password",
                    attrs: {
                      "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.show1 ? "text" : "password",
                      rules: [() => !!_vm.passwordTOTVS || "INFORME A  SENHA"],
                      label: "Senha de usuario",
                      placeholder: "SENHA",
                      required: "",
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.show1 = !_vm.show1
                      },
                    },
                    model: {
                      value: _vm.passwordTOTVS,
                      callback: function ($$v) {
                        _vm.passwordTOTVS = $$v
                      },
                      expression: "passwordTOTVS",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitLogin()
                        },
                      },
                    },
                    [_vm._v(" LOGAR ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }