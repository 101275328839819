<template>
  <v-app id="inspire" class="bg-slate-800">
    <!-- <v-app id="inspire"   style="background-color: rgb(232, 232, 232);"> -->

    <div v-if="checkLogin()">
      <v-system-bar window dark>
        <v-icon>mdi-message</v-icon>
        <span>10 unread messages</span>
        <v-spacer></v-spacer>
        <v-icon>mdi-minus</v-icon>
        <v-icon>mdi-checkbox-blank-outline</v-icon>
        <v-icon>mdi-close</v-icon>
      </v-system-bar>

      <v-navigation-drawer v-model="drawer" app>

        <Navigation v-if="this.drawer" @close="drawerValidate" />
      </v-navigation-drawer>

      <v-app-bar app dark color="black">
        <v-app-bar-nav-icon @click="drawerValidate"></v-app-bar-nav-icon>

        <v-toolbar-title>RODOAPP</v-toolbar-title>
        <div v-if="$store.state.branchSelected" style="position: absolute;right: 0px;margin-right: 10px;">
          {{ $store.state.branchSelected.filial }}
        </div>
      </v-app-bar>

      <v-main>

        <router-view />
      </v-main>

    </div>
    <div v-if="!checkLogin()">
      <Login />
    </div>

    <Permission v-if="!this.validUser" />

  </v-app>
</template>

<script>
import Navigation from '../src/components/navigation'
import Login from '../src/components/login'
import Permission from '../src/components/loginSuper'
export default {
  name: 'App',
  components: {
    Navigation,
    Login,
    Permission,
    validUser: null,
    currentRouteName: ''
  },

  data: () => ({
    drawer: false,

    apiUrl: 'https://172.16.99.174:8400/rest', //'http://rodoparanaimplementos120531.protheus.cloudtotvs.com.br:4050/rest/ ',
    items: [], // Armazenará os dados da API

    listProducts: [],
    hasNext: null

  }),

  methods: {
    drawerValidate() {
      this.drawer = this.drawer ? false : true
    },
    beforeRouteUpdate(to, from, next) {
      // Este gancho é chamado sempre que a rota é atualizada
      this.currentRouteName = this.$router.currentRoute.name;
      next();
    },

    async takeProducts() {
      await this.$store.dispatch('getAllProducts')
    },

    async takeBranch() {
      await this.$store.dispatch('getAllBranch')
    },

    async takePayments() {
      await this.$store.dispatch('getAllPayments')

    },
    checkLogin() {
      const loginTime = localStorage.getItem('loginTime');
      const token = localStorage.getItem('token');

      if (token) {
        const currentTime = new Date().getTime();
        const thirtyMinutesInMillis = 12 * 60 * 60 * 1000; // 30 minutos em milissegundos

        if (currentTime - parseInt(loginTime, 10) > thirtyMinutesInMillis) {
          // Já passaram 30 minutos desde o login. Faça o que for necessário.
          localStorage.removeItem('token');

          return false

        } else {
          // Ainda não passaram 30 minutos desde o login.
          this.takeListLogin()
          return true

        }

      } else {
        return false
      }
    },
    async takeListLogin() {
      await this.$store.dispatch('takeListLogin');
    },
    async takeDados() {
      await this.$store.dispatch('getDados');
    },
    checkPermission() {
      if (this.$store.state.permission) {
        this.validUser = true
        return true
      }
      this.validUser = true
      return true
    }, verifyDataTbn() {


      axios.get(`${this.$store.state.linkBaseApiProtheusHomologation}reidoapsdu/consultar/ambiente`)
        .then(response => {
          console.log("Aqui", response);
          this.itemInfo = response;

          // Check if the response indicates success
          if (response) {
            console.log("foi");
          } else {
            console.log("não foi");
          }
        })
        .catch(error => {
          // Handle errors here
          console.error("Error:", error.message);
          window.alert("Erro ao tentar acessar a API. Por favor, tente novamente.");
          this.verifyDataTbn()
          // You can also update your state or show an error message to the user if needed
          // this.errorMessage = "An error occurred. Please try again.";
        });

      this.requestModal = true
    },
  },

  created() {
    this.takeListLogin()
    this.takeProducts()
    this.takeDados()

    this.takeBranch()
    this.takePayments()

    this.checkLogin()
    this.checkPermission()
    this.currentRouteName = this.$router.currentRoute.name;
  }
};
</script>

<style>
.aurean-margin-list {
  margin: 13px;
}

.aurean-card-actions {
  height: 66px;
  margin: 1px;
}
</style>
