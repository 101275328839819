import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '../firebaseDb'

Vue.use(Vuex)
import axios from 'axios'
// var raiz = 'http://172.16.99.174:8400' //PRODUCAO
var raiz = 'http://172.16.99.174:8410' //PRODUCAO
// var raiz = 'http://172.16.0.245:8410' //HOMOLOGACAO
export default new Vuex.Store({
  state: {
    productsList: null,
    filiaisList: null,
    addCondPagList: null,
    addCcList: null,
    branchsList: null,
    branchSelected: null,
    paymentsList: null,
    permission: null,//'http://172.16.99.174:8400'
    linkBaseApiProtheusProduction: `${raiz}/rest/`,//'http://rodoparanaimplementos120531.protheus.cloudtotvs.com.br:4050/rest/',
    linkBaseApiProtheusHomologation: `${raiz}/rest/`,//'http://rodoparanaimplementos120532.protheus.cloudtotvs.com.br:4050/rest/',
    apiHom: `${raiz}/rest/reidoapsdu/`,
    api: `${raiz}/rest/`,
    URLANEXO: `http://172.16.99.144:3001/upload`,
    URLADELANEXO: `http://172.16.99.144:3001/delete`
  },
  mutations: {
    addProduct(state, products) {
      state.productsList = products
    },
    addFiliais(state, filiais) {
      state.filiaisList = filiais
    },
    addCondPag(state, cond) {
      state.addCondPagList = cond
    },
    addCCusto(state, cc) {
      state.addCcList = cc
    },
    addBranch(state, branch) {
      state.branchsList = branch.data
    },
    addPayment(state, payment) {
      state.paymentsList = payment.data
    },
    selectedBranch(state, branch) {
      state.branchSelected = branch
    },
    selectedBranch(state, branch) {
      state.branchSelected = branch
    },
    numberMaskReturn(state, number) {
      return 3
    },
    setPermission(state, permission) {
      state.permission = permission;
    },
  },
  actions: {
    getDados(context) {
      this.carregando = true;
      let API_URL = `${raiz}/rest/reidoapsdu/consultar/cargaInicio/`
      console.log(API_URL)
      axios.get(API_URL, {
        method: 'GET'
      }).then(response => {
        if (response.status !== 200) {
          console.error('Erro na solicitação:', response.status);
          throw new Error('Não foi possível acessar a API da TOTVS');
        }
        context.commit('addFiliais', response.data?.Filiais)
        context.commit('addCondPag', response.data?.Condicoes)
        context.commit('addCCusto', response.data?.CentoCusto)
      }).catch(error => {
        console.log(error)
      })
    },
    // async getAllProducts(context) {
    //   const url = `http://protheus-vm:8403/rest/api/retail/v1/retailItem?page=1&pageSize=50000&fields=code,description,MercosulNomenclature`
    //   let productsList = await axios.get(url).then(response => response)
    //   context.commit('addProduct', productsList)
    // }, 

    async getAllBranch(context) {
      const url = `${raiz}/rest/reidoapsdu/consultar/filiais/`
      let branchs = await axios.get(url).then(response => response)
      context.commit('addBranch', branchs)
    },

    async getAllPayments(context) {
      // const url = `http://protheus-vm:8403/rest/api/fat/v1/paymentcondition/?pageSize=50000`
      // let payments = await axios.get(url).then(response => response)
      // context.commit('addPayment', payments)
    },
    selectedBranch(context, branch) {
      // context.commit('selectedBranch', branch)
    },

    numberMask(context, number) {
      context.commit('numberMaskReturn', number)
    },
    takeListLogin({ commit, state }) {
      const user = localStorage.getItem('user aprovate');
      // db.collection('users').get().then(snapshot => {
      //   snapshot.forEach(doc => {
      //     let objectTask = doc.data();
      //     objectTask.id = doc.id;

      //     if (objectTask.name.trim().toUpperCase() === user.toUpperCase()) {
      //       commit('setPermission', objectTask);
      //     }
      //   });
      // });
    },
  },
})