<template>
    <v-row justify="center">

        <v-col cols="12" sm="10" md="8" lg="6" class="ma-4">
            <v-alert v-if="showAlert" type="error">
                <v-row align="center">
                    <v-col class="grow">
                        Falha na autenticação. Verifique suas credenciais.
                    </v-col>
                </v-row>
            </v-alert>

            <v-card ref="form">
                <v-card-text>
                    LOGIN:
                    <v-text-field ref="nameTOTVS" v-model="nameTOTVS"
                        :rules="[() => !!nameTOTVS || 'INFORME O USUARIO']" label="Nome de usuario"
                        placeholder="USUARIO" required></v-text-field>

                    <v-text-field ref="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1" :type="show1 ? 'text' : 'password'" v-model="passwordTOTVS"
                        :rules="[
                            () => !!passwordTOTVS || 'INFORME A  SENHA'
                        ]" label="Senha de usuario" placeholder="SENHA" required></v-text-field>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="submitLogin()">
                        LOGAR
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { db } from '../../firebaseDb'
export default {
    data() {
        return {
            showAlert: false,
            nameTOTVS: null,
            passwordTOTVS: null,
            show1: false,
        };
    },
    methods: {
        async submitLogin() {
            debugger
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    'username': this.nameTOTVS,//'alexander.santos',
                    'password': this.passwordTOTVS,//'236787',
                    'grant_type': 'password'
                };

                const response = await axios.post(
                    `${this.$store.state.linkBaseApiProtheusHomologation}api/oauth2/v1/token`,
                    null,
                    { headers: headers }
                );
                // Se a resposta foi bem-sucedida (código 2xx), você pode continuar processando a resposta normalmente.
                localStorage.setItem('token', response.data.access_token)
                const now = new Date()
                localStorage.setItem('loginTime', now.getTime())
                localStorage.setItem('user aprovate', this.nameTOTVS)
                const ret = await axios.get(
                    `${this.$store.state.apiHom}getNome?cCodUser=${this.nameTOTVS}`
                ).then(response => {
                    localStorage.setItem('idUser', response.data.Codigo)
                    localStorage.setItem('nomeUser', response.data.Nome)
                })
                location.reload()
            } catch (error) {
                // Se ocorrer um erro, verifique se é um erro 401.
                if (error.response && error.response.status === 401) {
                    console.error('Erro 401: Falha na autenticação. Verifique suas credenciais.');
                    this.showAlert = true
                    // Aqui você pode lidar com o erro 401 de acordo com seus requisitos, como solicitar credenciais válidas.
                } else {
                    // Se for outro tipo de erro, você pode lidar de acordo com seus requisitos.
                    console.error('Erro:', error.message);
                    this.showAlert = true
                }
            }

        },
    }
}
</script>
<style></style>