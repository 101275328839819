var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "permission" } },
    [
      _vm.listData
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(
              "Usuario sem acesso configurado, verificar com gestor imediato: " +
                _vm._s(_vm.listData[0].usuario.toUpperCase())
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }