<template>
    <v-app id="permission" >
        <v-alert v-if="listData"
            type="error"
        >Usuario sem acesso configurado, verificar com gestor imediato: {{ listData[0].usuario.toUpperCase() }}</v-alert>
    </v-app>
</template>
  
<script>
export default {
    name: 'LoginEditor',
    components:{
      
    },
    data: () => ({
            permissions: {
                bought:{title:'Compras', create: false, read: false, edit: false, delete: false },
                preNote:{title:'Pre nota', create: false, read: false, edit: false, delete: false },
                listPreNote:{title:'Listar pre notas', create: false, read: false, edit: false, delete: false }
            },
            listData:null
        }),
    methods:{
        verifyPermission(){
            if(this.permission && this.permission.listNote){
  
            } else {

                return false
            }
        },
      async takeData() {
        let API_URL = `${this.$store.state.linkBaseApiProtheusHomologation}reidoapsdu/consultar/checkSuper/`
                axios.get(API_URL,{
                    method: 'GET',
                    headers: {
                        'cpfCnpjForn': 'mateus.vaz'
                    }
                })
                .then(response => {
                    if (response.status !== 200 ) {
                        console.error('Erro na solicitação:', response.status);
                        throw new Error('Não foi possível acessar a API da TOTVS');
                    }

                    this.listData = response.data
                })
                .catch(error => {
                    console.error('Erro:', error);
                });
      	 },
    },
    created(){
        this.permissions['bought'].create = true
        this.takeData()
    }
  
  };
</script>
  
<style>
</style>
  