<template>
	<div id="app">
		<v-app id="inspire">
			<v-card height="400" width="256" class="mx-auto">
				<v-navigation-drawer permanent style="border-radius: 0%;" color="black" height="100vh"
					class="overflow-y-auto">
					<v-list-item>
						<v-list-item-content class="overflow-hidden">
							<v-list-item-title class="text-h6">
								<img :src="require('@/assets/RODO_LOGO.svg')" />
							</v-list-item-title>

							<v-list-item-subtitle style="color:white">
								BEM VINDO
								{{ user.toUpperCase() }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<div id="nav-container">
						<div class="toggle-icon">
							<span class="bar"></span>
							<span class="bar"></span>
							<span class="bar"></span>
						</div><!-- /toggle-icon -->
					</div><!-- /nav-container -->
					<v-divider></v-divider>

					<v-list dense nav dark>

						<v-list-item v-for="(item, index) in menuItens" :key="index" @click="navRouter(item.router)"
							link>

							<v-list-item-icon>
								<v-icon class="white--text">{{ item.icon }}</v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<template v-slot:append>
						<div class="pa-2">
							<v-btn block @click="logout()" color="green" dark>
								Logout
							</v-btn>
						</div>
					</template>
				</v-navigation-drawer>
			</v-card>
		</v-app>
	</div>
</template>

<script scoped>
export default {

	data() {
		return {

			selectMenu: 'Compras',
			menuItens: [
				{ title: this.$t('navgation.home'), mainArea: "Home", icon: 'mdi-home', router: 'home', valid: true },
				// { title: this.$t('navgation.listRequest'),mainArea:"Compras", icon: 'mdi-book-open-page-variant', router:'listRequest',valid:true},
				// { title: this.$t('borrow'), icon: 'mdi-home', router:'borrow' },
				// { title: this.$t('support'), icon: 'mdi-list-box', router:'ticketSupport'},

				// { title: 'Consultar notas', mainArea:"Compras",icon: 'mdi-note-search', router:'sefazNote',valid:true},

				{ title: 'Gerar pre nota', mainArea: "Compras", icon: 'mdi-format-list-group-plus', router: 'ListPreNote', valid: true },
				{ title: 'Financeiro', mainArea: "Compras", icon: 'mdi-currency-usd', router: 'financeiro', valid: true },
				// { title: 'Listar pre nota', mainArea:"Compras",icon: 'mdi-format-list-group', router:'listNote' ,valid:true},
				// { title: 'Solicitação de compra', mainArea:"Compras",icon: 'mdi-animation-play', router:'listprerequest' ,valid:true},
				// { title: 'Cotação de compra', mainArea:"Compras",icon: 'mdi-animation', router:'listpricerequest' ,valid:true},
			],

			user: localStorage.getItem('user aprovate'),
			right: null,
		}
	},

	methods: {
		navRouter(nameRouter) {
			this.$emit('close');
			this.$route.name != nameRouter ? this.$router.push({ name: nameRouter }) : ''
		},
		logout() {
			localStorage.removeItem('token')
			location.reload()
		}
	},
};
</script>
