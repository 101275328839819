var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "bg-slate-800", attrs: { id: "inspire" } },
    [
      _vm.checkLogin()
        ? _c(
            "div",
            [
              _c(
                "v-system-bar",
                { attrs: { window: "", dark: "" } },
                [
                  _c("v-icon", [_vm._v("mdi-message")]),
                  _c("span", [_vm._v("10 unread messages")]),
                  _c("v-spacer"),
                  _c("v-icon", [_vm._v("mdi-minus")]),
                  _c("v-icon", [_vm._v("mdi-checkbox-blank-outline")]),
                  _c("v-icon", [_vm._v("mdi-close")]),
                ],
                1
              ),
              _c(
                "v-navigation-drawer",
                {
                  attrs: { app: "" },
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                },
                [
                  this.drawer
                    ? _c("Navigation", { on: { close: _vm.drawerValidate } })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-app-bar",
                { attrs: { app: "", dark: "", color: "black" } },
                [
                  _c("v-app-bar-nav-icon", {
                    on: { click: _vm.drawerValidate },
                  }),
                  _c("v-toolbar-title", [_vm._v("RODOAPP")]),
                  _vm.$store.state.branchSelected
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "0px",
                            "margin-right": "10px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$store.state.branchSelected.filial) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-main", [_c("router-view")], 1),
            ],
            1
          )
        : _vm._e(),
      !_vm.checkLogin() ? _c("div", [_c("Login")], 1) : _vm._e(),
      !this.validUser ? _c("Permission") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }