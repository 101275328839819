import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../components/home')
  },


  {
    path: '/homerequest',
    name: 'homerequest',
    component: () => import('../components/home/homeRequest')
  },
  {
    path: '/financeiro',
    name: 'financeiro',
    component: () => import('../components/financeiro')
  },
  {
    path: '/faturas/:id?',
    name: 'faturas',
    component: () => import('../components/financeiro/faturas')
  },

  {
    path: '/areaconfig',
    name: 'areaconfig',
    component: () => import('../components/home/areaConfig')
  },

  {
    path: '/create',
    name: 'create',
    component: () => import('../components/request/createRequest')
  },

  {
    path: '/created/:id',
    name: 'createdWithId',
    props: (route) => ({
      id: route.params.id,
      editData: route.query.editData,
    }),
    component: () => import('../components/request/createRequest')
  },

  {
    path: '/listRequest',
    name: 'listRequest',
    component: () => import('../components/request/listRequest')
  },
  
  {
    path: '/product',
    name: 'product',
    component: () => import('../components/request/products')
  },

  {
    path: '/borrow',
    name: 'borrow',
    component: () => import('../components/borrow')
  },

  {
    path: '/ticketSupport',
    name: 'ticketSupport',
    component: () => import('../components/ticketSupport')
  },
  {
    path: '/createNewSupport',
    name: 'createNewSupport',
    component: () => import('../components/ticketSupport/createNewSupport')
  },
  {
    path: '/requestSupport',
    name: 'requestSupport',
    component: () => import('../components/ticketSupport/requestSupport')
  },
  
  {
    path: '/sefazNote',
    name: 'sefazNote',
    component: () => import('../components/sefazNote')
  },
  {
    path: '/preNote/:id?',
    name: 'preNote',
    component: () => import('../components/preNote/preNote')
  },
  {
    path: '/classifica/:id?',
    name: 'classifica',
    component: () => import('../components/preNote/classifica')
  },
  {
    path: '/ListPreNote',
    name: 'ListPreNote',
    component: () => import('../components/preNote')
  },
  {
    path: '/AlteraPreNota/:id',
    name: 'AlteraPreNota',
    props: (route) => ({
      id: route.params.id}),
    component: () => import('../components/preNote/alterar')
  },
  {
    path: '/listNote',
    name: 'listNote',
    component: () => import('../components/listNote')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/login')
  },
  {
    path: '/logineditor',
    name: 'logineditor',
    component: () => import('../components/loginEditor')
  },
  {
    path: '/permission',
    name: 'permission',
    component: () => import('../components/loginSuper')
  }
  ,
  {
    path: '/listlogin',
    name: 'listlogin',
    component: () => import('../components/listLogin')
  },
  {
    path: '/prerequest',
    name: 'prerequest',
    component: () => import('../components/preRequest')
  },
  {
    path: '/listprerequest',
    name: 'listprerequest',
    component: () => import('../components/preRequest/list')
  },
  {
    path: '/listpricerequest',
    name: 'listpricerequest',
    component: () => import('../components/priceRequest/list')
  },
  {
    path: '/pricerequest',
    name: 'pricerequest',
    component: () => import('../components/priceRequest')
  },
  {
    path: '/approverconfig',
    name: 'approverconfig',
    component: () => import('../components/approverConfig')
  },
  {
    path: '/approver',
    name: 'approver',
    component: () => import('../components/approverArea')
  },
  {
    path: '/approve',
    name: 'approve',
    component: () => import('../components/approverArea/approve')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
