var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { height: "400", width: "256" } },
            [
              _c(
                "v-navigation-drawer",
                {
                  staticClass: "overflow-y-auto",
                  staticStyle: { "border-radius": "0%" },
                  attrs: { permanent: "", color: "black", height: "100vh" },
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "pa-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    block: "",
                                    color: "green",
                                    dark: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.logout()
                                    },
                                  },
                                },
                                [_vm._v(" Logout ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "overflow-hidden" },
                        [
                          _c("v-list-item-title", { staticClass: "text-h6" }, [
                            _c("img", {
                              attrs: { src: require("@/assets/RODO_LOGO.svg") },
                            }),
                          ]),
                          _c(
                            "v-list-item-subtitle",
                            { staticStyle: { color: "white" } },
                            [
                              _vm._v(
                                " BEM VINDO " +
                                  _vm._s(_vm.user.toUpperCase()) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { attrs: { id: "nav-container" } }, [
                    _c("div", { staticClass: "toggle-icon" }, [
                      _c("span", { staticClass: "bar" }),
                      _c("span", { staticClass: "bar" }),
                      _c("span", { staticClass: "bar" }),
                    ]),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    { attrs: { dense: "", nav: "", dark: "" } },
                    _vm._l(_vm.menuItens, function (item, index) {
                      return _c(
                        "v-list-item",
                        {
                          key: index,
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              return _vm.navRouter(item.router)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(item.icon)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "white--text" },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }